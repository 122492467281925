
import { VuetifyMixin, BAIconsMixin, StringsMixin, AthleteRoutingMixin, HelpInfoMixin, ScoutingReportMixin, CoachRoutingMixin } from '@/mixins';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { ScoutingReportModelEx } from '@/models/scoutingReport/ScoutingReportModel';
import ScoutingReportScoreChip from "@/components/scoutingReport/ScoutingReportScoreChip.vue";
import ScoutingReportSpiderChart from "@/components/scoutingReport/ScoutingReportSpiderChart.vue";
import { scoutingReportApi } from '@/api/ScoutingReportApi';
import { notificationStore } from '@/store';

@Component({
	components: {
		ScoutingReportScoreChip,
		ScoutingReportSpiderChart,
	}
})
export default class ScoutingReportBrief extends Mixins(BAIconsMixin, StringsMixin, VuetifyMixin, ScoutingReportMixin, AthleteRoutingMixin, CoachRoutingMixin, HelpInfoMixin){
	@Prop() scoutingReport: ScoutingReportModelEx;

	goToScoutingReportView(reportId: string) {
		if( this.CoachAppActive || this.AdminAppActive ) {
			this.gotoCoachViewScoutingReport(reportId);
		} else {
			this.goToScoutingReport(reportId);
		}
	}

	async onDeleteReport(report: ScoutingReportModelEx) {

		if( confirm(`Are you sure you want to delete this scouting report for ${report.AthleteFullName}?`) ) {
			try{
				await scoutingReportApi.delete(report);
				notificationStore.pushSnackbarWarning({message: `Deleted scouting report for ${report.AthleteFullName}`});
			} catch(e) {
				notificationStore.pushSnackbarError({message: `Error deleting report ${report.id}: ${e}`})
			}
		}
	}
}
