
import { Component, Vue, Prop } from 'vue-property-decorator';
import ScoutingReportScoreChip from "@/components/scoutingReport/ScoutingReportScoreChip.vue";
import RadarChart from "@/components/charts/RadarChart.vue";
import RadarChartJS from "@/components/charts/RadarChartJS.vue";

@Component({
	components:{ ScoutingReportScoreChip, 
				 RadarChart,
				 RadarChartJS
			   },
})
export default class ScoutingReportSpiderChart extends Vue {
	@Prop({ type: Boolean }) available;
	@Prop({ required: true }) public heading: string;
	@Prop({ required: true }) public labels: string[];
	@Prop({ required: true }) public scores: number[];
	@Prop({ type: Boolean }) hideScore;

	shortLabel(label: string, length: number = 3): string {
		// Split camelCase into words and also split by spaces
		const words = label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert spaces before uppercase letters in camelCase
        .trim()
        .split(/\s+/); // Split by spaces
		 
		let short = words.map(word => word[0]).join(""); // Take first letter of each word
		if (short.length < length) {
			// Add more characters from the last word until short is 'length' characters
			const lastWord = words[words.length - 1];
			let i = 1;
			while (short.length < length && i < lastWord.length) {
				short += lastWord[i];
				i++;
			}
		}

		return short.toUpperCase(); // Return uppercase for consistency
	}
}
